<template>
  <div class="host-register-bank-account">
    <h1 class="host-register-bank-account__header">
      <span class="header__text"> 계좌 추가하기 </span>
    </h1>
    <div class="host-register-bank-account__body">
      <ui-form ref="form" :formData="formData" @submit="onSubmitForm" :error="onErrorForm" :ignoreSubmit="true">
        <ui-form-list>
          <ui-form-list-multiple-item>
            <ui-select
              :type="formData.bank_code.type"
              v-model="formData.bank_code.value"
              :items="bankOptions"
              :label="formData.bank_code.label"
              :placeholder="formData.bank_code.placeholder"
              :error="formData.bank_code.error"
            />
            <ui-textbox
              :type="formData.bank_owner_name.type"
              v-model="formData.bank_owner_name.value"
              :label="formData.bank_owner_name.label"
              :placeholder="formData.bank_owner_name.placeholder"
              :error="formData.bank_owner_name.error"
            />
          </ui-form-list-multiple-item>
          <ui-form-list-item>
            <ui-textbox
              :type="formData.bank_account.type"
              :inputmode="formData.bank_account.inputmode"
              v-model="formData.bank_account.value"
              :label="formData.bank_account.label"
              :placeholder="formData.bank_account.placeholder"
              :error="formData.bank_account.error"
              :mask="formData.bank_account.mask"
            />
          </ui-form-list-item>
        </ui-form-list>
      </ui-form>
    </div>
    <div class="host-register-bank-account__footer">
      <div class="button-area">
        <button type="button" class="btn btn--close" @click="onClickClose">닫기</button>
        <button type="button" class="btn btn--submit" @click="onClickSubmit">추가</button>
      </div>
    </div>
  </div>
</template>

<script>
const BANK_COUNTRY = {
  KOREA: 'KR',
};

export default {
  data() {
    return {
      formData: {
        bank_code: {
          type: 'select',
          label: '은행',
          placeholder: '은행을 선택해주세요',
          value: '',
          error: '',
          validation: [{ type: 'required' }],
        },
        bank_account: {
          type: 'text',
          inputmode: 'numeric',
          label: '계좌번호',
          placeholder: '계좌번호를 입력해주세요 (숫자만 입력해주세요)',
          value: '',
          error: '',
          validation: [{ type: 'required', type: 'number' }],
        },
        bank_owner_name: {
          type: 'text',
          label: '예금주',
          placeholder: '예금주를 입력해주세요',
          value: '',
          error: '',
          validation: [{ type: 'required' }],
        },
      },

      loading: false,
    };
  },

  watch: {
    loading(newValue) {
      if (newValue) {
        this.$store.commit('loading/SET_TRUE');
      } else {
        this.$store.commit('loading/SET_FALSE');
      }
    },
  },

  computed: {
    bankOptions() {
      return this.$store.getters['bankAccounts/GET_BANK_CODES'].map((o) => {
        return {
          label: o.name,
          value: o.code,
        };
      });
    },
  },

  methods: {
    async fetchAllBankCodes() {
      try {
        this.loading = true;
        await this.$store.dispatch('bankAccounts/fetchAllCodes');
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },

    onClickClose() {
      this.$emit('close');
    },

    onClickSubmit() {
      this.$refs.form.submit();
    },

    onErrorForm(e) {
      this.$store.commit('alert/ADD_ITEM', { message: e, status: 'error' });
    },

    async onSubmitForm({ bank_code, bank_account, bank_owner_name }) {
      try {
        this.loading = false;
        const bank_country = BANK_COUNTRY.KOREA;
        const bank_name = this.bankOptions.find(({ value }) => value === bank_code)?.label;

        const payload = {
          bank_owner_name,
          bank_name,
          bank_country,
          bank_code,
          bank_account: bank_account.toString().replace(/\D/g, ''),
          list: 'MyBankAccounts',
        };

        const data = await this.$store.dispatch('bankAccounts/createOne', payload);

        this.$emit('submit', data);

        this.$store.commit('notice/ADD_ITEM', { message: this.$__t('등록되었습니다.') });
        this.loading = false;
      } catch (e) {
        this.$store.commit('alert/ADD_ITEM', { message: e, status: 'error' });
      } finally {
        this.loading = false;
        this.$emit('close');
      }
    },
  },

  created() {
    if (this.bankOptions.length < 1) {
      this.fetchAllBankCodes();
    }
  },
};
</script>

<style lang="scss" scoped>
.host-register-bank-account {
  &__header {
    .header__text {
      font-size: unit(22);
      font-weight: bold;
    }
  }

  &__body {
    padding: unit(16) 0;
  }

  &__footer {
    position: sticky;
    bottom: 0;
    padding: unit(16) 0;

    .button-area {
      display: flex;
      align-items: center;
      gap: unit(8);
    }
  }

  .btn {
    padding: unit(8);
    cursor: pointer;

    &--submit {
      flex: 1;
      background: $color-primary;
      font-size: unit(16);
      font-weight: 500;
      color: $color-white;
      border-radius: unit(6);
    }

    &--add {
      font-size: unit(12);
      color: #61676c;
      padding: 0;
    }

    &--close {
      flex: 0 0 unit(100);
      background: $color-white;
      font-size: unit(16);
      font-weight: 500;
      border: 1px solid $color-gray-4;
      border-radius: unit(6);
      color: $color-gray-4;
    }
  }
}
</style>
