<template>
  <div class="page host-settlement-request">
    <div class="page-header">
      <h1 class="page-header__title">정산 신청</h1>
    </div>

    <div class="page-content">
      <ui-form ref="form" :formData="formData" @error="scrollToError" :ignoreSubmit="true" @submit="submitForm">
        <!--정산기간-->
        <div class="section section-duration">
          <div class="section-header">
            <h2 class="section-header__title">정산 기간</h2>
          </div>
          <div class="section-body">
            <!-- <p class="text">{{ data.settled_date_start }} ~ {{ data.settled_date_end }}</p> -->
            <p class="text">{{ data.date_start }} ~ {{ data.date_end }}</p>
          </div>
        </div>
        <!--계좌정보-->
        <div class="section section-account">
          <div class="section-header">
            <h2 class="section-header__title">계좌정보</h2>
            <button type="button" class="btn btn--add" @click="onClickBankAccount">
              {{ primaryAccount ? '계좌 수정' : '계좌 등록' }}
            </button>
          </div>
          <div class="section-body">
            <div class="table">
              <div class="table__row">
                <div class="table__cell table__cell--key">은행</div>
                <div class="table__cell">{{ bankName }}</div>
              </div>
              <div class="table__row">
                <div class="table__cell table__cell--key">계좌번호</div>
                <div class="table__cell">{{ bankAccount }}</div>
              </div>
              <div class="table__row">
                <div class="table__cell table__cell--key">예금주</div>
                <div class="table__cell">{{ bankOwnerName }}</div>
              </div>
            </div>
          </div>
        </div>
        <!--정산방식-->
        <div class="section section-method">
          <div class="section-header">
            <h2 class="section-header__title">정산 방식</h2>
          </div>
          <div class="section-body">
            <div class="form-item-area">
              <ui-radio
                :type="formData.settled_method.type"
                v-model="formData.settled_method.value"
                :error="formData.settled_method.error"
                :items="formData.settled_method.items"
              />
            </div>

            <ul class="description-list">
              <!--원천징수방식-->
              <li v-if="formData.settled_method.value === 'WITHHOLDING_TAX'" class="description-list__item">
                <h4 class="description-title">원천징수 방식</h4>
                <ul class="description-extra">
                  <li class="description-extra__item">개인 명의로 3.3% 세금 공제 후 정산금이 입금됩니다.</li>
                  <li class="description-extra__item">정산 정보 페이지에서 계좌번호를 입력해주세요.</li>
                  <li class="description-extra__callout-item">
                    <div class="callout">
                      첫 정산이실 경우 세금처리를 위해 <br /><strong>신분증 사본</strong>이 필요합니다.<br />
                      정산금 발생 시 개별 안내 예정이니 참고해주세요 :)
                    </div>
                  </li>
                </ul>
              </li>
              <!--세금계산서 방식-->
              <li v-else class="description-list__item">
                <h4 class="description-title">세금계산서 방식</h4>
                <ul class="description-extra">
                  <li class="description-extra__item">호스트가 <strong>직접 세금계산서를 발행</strong>하는 방식입니다.</li>
                  <li class="description-extra__item">정산 금액 확인 후 하단 내용대로 세금계산서를 발행해주세요.</li>
                  <li class="description-extra__item description-extra__item--nested">럭스테이 사업자 번호 : 689-87-01050</li>
                  <li class="description-extra__item description-extra__item--nested">세금계산서 항목 : 물품보관 수수료</li>
                  <li class="description-extra__item description-extra__item--nested">이메일 : support@lugstay.com</li>
                  <li class="description-extra__item">세금계산서 발행 후 정산정보 페이지에서 계좌정보를 입력해주세요.</li>
                  <li class="description-extra__callout-item">
                    <div class="callout">
                      세금계산서 발행이
                      <strong
                        >확인되지 않을 시<br />
                        정산금이 지급되지 않습니다.</strong
                      ><br />
                      계좌정보 입력 전 꼭 세금계산서를 먼저 발행해주세요.
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div class="section section-price">
          <div class="section-header">
            <h2 class="section-header__title">정산 금액</h2>
          </div>
          <div class="section-body">
            <dl class="definition-list">
              <dt class="definition-list__title">총 발생 금액(A)</dt>
              <dd class="definition-list__value">{{ profit }} {{ $__t('won') }}</dd>
              <dt class="definition-list__title">이용료 및 결제 수수료(B)</dt>
              <dd class="definition-list__value">- {{ fee }} {{ $__t('won') }}</dd>
              <dt v-if="formData.settled_method.value === 'WITHHOLDING_TAX'" class="definition-list__title">원천세(C)</dt>
              <dd v-if="formData.settled_method.value === 'WITHHOLDING_TAX'" class="definition-list__value">- {{ tax }} {{ $__t('won') }}</dd>
            </dl>

            <dl class="definition-list">
              <dt v-if="formData.settled_method.value === 'WITHHOLDING_TAX'" class="definition-list__title">호스트 수익(A-B-C)</dt>
              <dt v-else class="definition-list__title">호스트 수익(A-B)</dt>
              <dd class="definition-list__value definition-list__value--primary">{{ total }} {{ $__t('won') }}</dd>
            </dl>
          </div>
        </div>

        <div class="section section-guide">
          <div class="section-header">
            <h2 class="section-header__title">정산 가이드</h2>
          </div>
          <div class="section-body">
            <ul class="text-list">
              <li class="text-list__item">1) 정산 계좌에 문제가 있을 경우에는 정산이 불가능 하니 계좌 정보를 정확히 확인해 주세요.</li>
              <li class="text-list__item">2) 정산 신청일 기준 전월 결제 완료건 까지의 내역이 정산됩니다.</li>
              <li class="text-list__item">3) 정산신청일로부터 영업일 기준 5일 이내 정산이 완료 됩니다.</li>
            </ul>
          </div>
        </div>

        <div class="section section-footer">
          <div class="section-body">
            <div class="form-item-area">
              <ui-checkbox
                :type="formData.confirm.type"
                :label="formData.confirm.label"
                v-model="formData.confirm.value"
                :error="formData.confirm.error"
              />
            </div>
            <div class="button-area">
              <button
                type="button"
                @click.prevent="$refs.form.submit()"
                class="btn btn--submit"
                :disabled="!formActivated"
                :class="{ activated: formActivated }"
              >
                정산 신청하기
              </button>
            </div>
          </div>
        </div>
      </ui-form>
    </div>

    <ui-slider id="host-bank-account-slider" ref="host-bank-account-slider">
      <template v-if="primaryAccount">
        <HostEditBankAccount
          :id="primaryAccount.uid"
          @submit="$refs['host-bank-account-slider'].close()"
          @close="$refs['host-bank-account-slider'].close()"
        />
      </template>
      <template v-else>
        <HostRegisterBankAccount @submit="$refs['host-bank-account-slider'].close()" @close="$refs['host-bank-account-slider'].close()" />
      </template>
    </ui-slider>
  </div>
</template>

<script>
import HostRegisterBankAccount from '@/components/resources/host/HostRegisterBankAccount';
import HostEditBankAccount from '@/components/resources/host/HostEditBankAccount';
import UiSlider from '@/components/modules/Slider/Body.vue';
import { openRef } from '@/helpers';
export default {
  name: 'HostSettlementRequest',
  components: {
    HostRegisterBankAccount,
    HostEditBankAccount,
    UiSlider
  },
  data() {
    return {
      formData: {
        settled_method: {
          type: 'radio',
          label: '정산 방식',
          items: [
            { label: '원천징수 방식', value: 'WITHHOLDING_TAX' },
            { label: '세금 계산서', value: 'TAX_BILL' }
          ],
          value: 'WITHHOLDING_TAX',
          error: '',
          validation: [{ type: 'required' }]
        },
        confirm: {
          type: 'checkbox',
          label: '위 안내사항을 모두 숙지하였습니다.',
          value: false,
          error: '',
          validation: [{ type: 'required' }]
        }
      },

      data: {}
    };
  },

  watch: {
    formData: {
      handler(newValue, oldValue) {
        this.fetchDashboard();
      },
      deep: true
    }
  },

  computed: {
    primaryAccount() {
      return this.accountList.length > 0 ? this.accountList[0] : null;
    },

    accountList() {
      return this.$store.getters['bankAccounts/GET_ITEMS_BY_LIST']({
        list: 'MyBankAccounts'
      }).sort();
    },

    storeId() {
      return this.$route.query.store_id;
    },

    formActivated() {
      let filled = !!this.primaryAccount;

      for (const key in this.formData) {
        const validation = this.formData[key]?.validation;
        if (Array.isArray(validation) && validation.find((o) => o.type === 'required') && !this.formData[key].value) {
          filled = false;
          break;
        }
      }

      return filled;
    },

    profit() {
      // return (this.data.total_origin || 0).toLocaleString();
      let total = 0;
      this.data?.items?.forEach((profit) => {
        total = total + profit.total_origin;
      });
      // return total.toLocaleString();
      return total.toLocaleString('ko-KR');
    },

    fee() {
      // return (this.data.total_fee || 0).toLocaleString();
      let totalFee = 0;
      this.data?.items?.forEach((item) => {
        totalFee = totalFee + item.total_fee;
      });
      return totalFee.toLocaleString('ko-KR');
    },

    tax() {
      // return (this.data.total_tax || 0).toLocaleString();
      // let totalTax = 0;
      // this.data?.items?.forEach((item) => {
      //   totalTax = totalTax + item.total_tax;
      // });
      // return totalTax.toLocaleString();
      return (this.data.tax || 0).toLocaleString('ko-KR');
    },

    total() {
      // return (this.data.settled_total || 0).toLocaleString();
      // let hostProfit = 0;
      // this.data?.items?.forEach((profit) => {
      //   hostProfit = hostProfit + profit.total_profit;
      // });
      // return hostProfit.toLocaleString();
      return (this.data.profit - this.data.tax || 0).toLocaleString('ko-KR');
    },

    // bank
    bankName() {
      return this.primaryAccount?.bank_name;
    },

    bankAccount() {
      return this.primaryAccount?.bank_account;
    },

    bankOwnerName() {
      return this.primaryAccount?.bank_owner_name;
    }
  },

  methods: {
    async fetchDashboard() {
      try {
        this.$store.commit('loading/SET_TRUE');
        this.data = await this.$store.dispatch('hostSettlements/dashboard', {
          name: 'settled_method',
          settled_method: this.formData.settled_method.value,
          description: this.formData.settled_method.value
          // store_id: this.storeId
        });
      } catch (e) {
      } finally {
        this.$store.commit('loading/SET_FALSE');
      }
    },

    async fetchBankAccount() {
      try {
        this.$store.commit('loading/SET_TRUE');
        this.data = await this.$store.dispatch('bankAccounts/fetchList', {
          page: 1,
          size: 100,
          list: 'MyBankAccounts'
        });
      } catch (e) {
      } finally {
        this.$store.commit('loading/SET_FALSE');
      }
    },

    scrollToError() {
      setTimeout(() => {
        const errorList = document.querySelectorAll('.ui-form div.error');
        if (errorList.length >= 0) {
          const elOffset = $(errorList[0]).offset().top;
          const elHeight = $(errorList[0]).height();
          const windowHeight = $(window).height();

          let offset;

          if (elHeight < windowHeight) {
            offset = elOffset - (windowHeight / 2 - elHeight / 2);
          } else {
            offset = elOffset;
          }

          $('html, body').animate({ scrollTop: offset }, 200);
        }
      }, 0);
    },

    submitForm(formData) {
      this.requestSettlement(formData).then((_) => {
        this.$router.replace({ name: 'HostSettlements' });
      });
    },

    async requestSettlement({ settled_method }) {
      try {
        this.$store.commit('loading/SET_TRUE');

        const { uid } = this.primaryAccount;
        await this.$store.dispatch('hostSettlements/request', {
          settled_method,
          bank_account_id: uid,
          id: this.storeId
        });

        this.$store.commit('alert/ADD_ITEM', {
          message: '정산 금액 지급이 신청되었습니다.',
          status: 'success'
        });
      } catch (e) {
        console.error(e);
        this.$store.commit('alert/ADD_ITEM', { message: e, status: 'error' });
      } finally {
        this.$store.commit('loading/SET_FALSE');
      }
    },

    onClickBankAccount() {
      if (this.primaryAccount) {
        openRef.call(this, 'host-bank-account-slider');
      } else {
        openRef.call(this, 'host-bank-account-slider');
      }
    }
  },

  created() {
    this.fetchDashboard();

    if (this.accountList.length < 1) {
      this.fetchBankAccount();
    }
  }
};
</script>

<style lang="scss" scoped>
.page.host-settlement-request {
  .page-header {
    padding: unit(24) unit(16);

    &__title {
      font-weight: bold;
      font-size: unit(22);
    }
  }
  .page-content {
    .section {
      .section-header {
        display: flex;
        justify-content: space-between;
        padding: unit(12) unit(16);

        &__title {
          font-size: unit(18);
          color: #61676c;
          font-weight: 500;
        }

        .btn {
          width: auto;
          margin: 0;
          cursor: pointer;
          font-size: unit(14);
          color: $color-white;
          font-weight: 500;
          padding: unit(4) unit(8);
          border-radius: $radius;
          background-color: $color-primary;
        }
      }

      .section-body {
        padding: 0 unit(16);

        .text {
          font-size: unit(14);
          line-height: 1.5;

          &__item {
            margin-top: unit(12);
          }
        }

        .table {
          display: table;
          table-layout: fixed;
          width: 100%;
          border-collapse: collapse;

          &__row {
            display: table-row;
          }

          &__cell {
            display: table-cell;
            padding: unit(8) unit(12);
            border: solid 1px #eaeeef;

            &--key {
              color: #61676c;
              background: #f1f5f5;
              width: auto;
            }
          }
        }

        .form-item-area::v-deep {
          .ui-radio {
            padding: unit(8) 0 unit(4) 0;
          }
        }

        .btn {
          &--submit {
            height: unit(50);
            border-radius: unit(6);
            background: #a9afb3;
            color: $color-white;
            font-weight: 500;
            font-size: unit(16);

            &.activated {
              background: $color-primary;
            }
          }
        }

        .definition-list {
          display: flex;
          flex-wrap: wrap;
          align-content: flex-end;
          align-items: flex-end;
          gap: unit(3);

          & > * {
            flex: 1 1 calc(50% - #{unit(6)});
          }

          &__title {
            text-align: left;
            font-size: unit(14);

            &--primary {
              font-weight: 500;
            }
          }

          &__value {
            text-align: right;
            font-size: unit(16);
            font-weight: 500;

            &--primary {
              font-size: unit(18);
              font-weight: bold;
              color: $color-primary;
            }
          }

          & + .definition-list {
            margin-top: unit(12);
          }
        }
      }

      & + .section {
        margin-top: unit(24);
        border-top: solid 1px #eaeeef;
      }

      &.section-guide {
        background: #f1f5f5;
        color: #61676c;
        padding-bottom: unit(16);

        .section-header {
          &__title {
            font-size: unit(14);
          }
        }

        .text-list {
          &__item {
            font-size: unit(12);
            color: #61676c;

            + * {
              margin-top: unit(8);
            }
          }
        }

        .form-item-area {
          padding: unit(16) 0;
        }
      }

      &.section-method {
        .description-list {
          background: #f1f5f5;
          margin-top: unit(24);
          padding: unit(16);

          &__item {
            + * {
              margin-top: unit(24);
            }
          }
        }

        .description-title {
          color: #61676c;
          font-size: unit(16);
          font-weight: 500;
        }

        .description-extra {
          margin-top: unit(1.6);

          &__item {
            line-height: 1.5;
            font-size: unit(12);
            color: #61676c;

            &::before {
              content: ' · ';
              display: inline;
            }

            + * {
              margin-top: unit(1.5);
            }

            &--nested {
              padding-left: unit(40);
            }

            strong {
              font-weight: bold;
            }
          }

          &__callout-item {
            margin-top: unit(20);
          }
        }

        .callout {
          color: $color-white;
          font-size: unit(14);
          background: $color-primary;
          border-radius: $radius;
          line-height: 1.5;
          padding: unit(16);

          strong {
            font-size: unit(16);
            font-weight: bold;
          }
        }
      }

      &.section-footer {
        position: sticky;
        border: 0;
        bottom: 0;
        padding: unit(16) 0;
        -webkit-backdrop-filter: blur(unit(3));
        backdrop-filter: blur(unit(3));
        background: rgba(255, 255, 255, 0.9);

        .button-area {
          margin-top: unit(12);
        }
      }
    }
  }
}
</style>
